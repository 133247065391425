import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./whyus.css";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init();
export default function WhyUs() {
  const [isGifPlaying, setIsGifPlaying] = useState(false);
  const [hasPlayedGif, setHasPlayedGif] = useState(false);
  const sectionRef = useRef(null);
  const gifRef = useRef(null);

  useEffect(() => {
    // Function to play the gif once after landing
    const playGifOnce = () => {
      setIsGifPlaying(true); // Set the gif to play
      setHasPlayedGif(true); // Update the state to indicate gif has played
      gifRef.current.removeEventListener("load", playGifOnce); // Remove the event listener
    };

    gifRef.current.addEventListener("load", playGifOnce); // Add the event listener to play gif once after load

    return () => {
      gifRef.current.removeEventListener("load", playGifOnce); // Cleanup: remove event listener
    };
  }, []);

  return (
    <div className="whyUs-section" ref={sectionRef}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 content1-whyus ">
          <h1
            className="small-screen-title"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Why Choose
            <br />
            RLG Logistics?
          </h1>
          <img
            ref={gifRef}
            src="/assets/gifs/WhyUs_GIF (1).gif"
            alt="GIF Image"
            style={{ visibility: isGifPlaying ? "visible" : "hidden" }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 content2-whyus">
          <div className="content-whyus-text">
            <h1 data-aos="fade-up" data-aos-delay="200">
              Why Choose
              <br />
              RLG Logistics?
            </h1>
            <p data-aos="fade-right" data-aos-delay="200">
              With our track record of prompt and dependable service, you can
              trust us to deliver your packages on time, every time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
