import React, { useEffect, useRef, useState } from "react";
import Button from "../ReusedComponents/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./landing.css";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init();

export default function Landing() {
  const [isGifPlaying, setIsGifPlaying] = useState(false);
  const [hasPlayedGif, setHasPlayedGif] = useState(false);
  const sectionRef = useRef(null);
  const gifRef = useRef(null);

  useEffect(() => {
    // Function to play the gif once after landing
    const playGifOnce = () => {
      setIsGifPlaying(true); // Set the gif to play
      setHasPlayedGif(true); // Update the state to indicate gif has played
      gifRef.current.removeEventListener("load", playGifOnce); // Remove the event listener
    };

    gifRef.current.addEventListener("load", playGifOnce); // Add the event listener to play gif once after load

    return () => {
      gifRef.current.removeEventListener("load", playGifOnce); // Cleanup: remove event listener
    };
  }, []);

  return (
    <div className="landing-section" ref={sectionRef}>
      <div className="content-landing row">
        <div className="content1 col-lg-6 col-md-6 col-sm-12 col-12 order-lg-1 order-second order-sm-2 order-md-2">
          <h1 data-aos="fade-right" data-aos-delay="200">
            Your Destination
            <br />
            is our Passion
          </h1>
          <p data-aos="fade-right" data-aos-delay="300">
            Your trusted global courier service, dedicated to delivering your
            packages swiftly and securely to destinations worldwide.
          </p>
          <div className="buttons" data-aos="fade-right" data-aos-delay="400">
            <AnchorLink href="#Services-section">
              <Button btnStyle="button orange" btnText="Discover Services" />
            </AnchorLink>
            <AnchorLink href="#ContactUs-section">
              <Button btnStyle="button outlineOrange" btnText="Contact Us" />
            </AnchorLink>
          </div>
        </div>
        <div className="content2 col-lg-6 col-md-6 col-sm-12 col-12 order-lg-2 order-first order-sm-1 order-md-1">
          <img
            ref={gifRef}
            src="assets/gifs/Landing_Gif (1).gif"
            alt="Landing GIF"
            style={{ visibility: isGifPlaying ? "visible" : "hidden" }}
          />
        </div>
      </div>
    </div>
  );
}
