import "bootstrap/dist/css/bootstrap.min.css";
import "./services.css";
import Button from "../ReusedComponents/Button";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Cards from "../ReusedComponents/cards";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init();
export default function Service() {
  return (
    <div className="services-section" id="Services-section">
      <div className="row">
        <div className="col-lg-7 content2-services">
          <div className="content-services-text">
            <h1 data-aos="fade-right" data-aos-delay="200">
              Express On Demand
              <br />
              Delivery Services
            </h1>
            <p data-aos="fade-right" data-aos-delay="300">
              We deliver What others Can’t
            </p>
          </div>
          <AnchorLink href="#ContactUs-section">
            <Button
              btnStyle="button outlineOrange servicesButton"
              btnText="Inquire Now"
            />
          </AnchorLink>
        </div>

        <div
          className="col-lg-5 content1-services"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="row big-screen-services">
            <div className="col-md-6">
              <Cards
                classCard="outer-cardright"
                cardText="Life Sciences"
                imageStyle="services-images"
                srcImage="assets/Images/LifeSciences.png"
              />
            </div>
            <div className="col-md-6">
              <Cards
                classCard="outer-cardleft"
                cardText="Medical Supplies"
                imageStyle="services-images"
                srcImage="assets/Images/MedicalSupplies.png"
              />
            </div>
            <div className="col-md-6">
              <Cards
                classCard="outer-cardright"
                cardText="Legal Documents"
                imageStyle="services-images"
                srcImage="assets/Images/LegalDocuments.png"
              />
            </div>
            <div className="col-md-6">
              <Cards
                classCard="outer-cardleft"
                cardText="Rush Delivery"
                imageStyle="services-images"
                srcImage="assets/Images/Fast-Delivery.png"
              />
            </div>
          </div>
          {/* small screen cards */}
          <div className="row small-screen-services">
            <div className="col-3  ">
              <Cards
                classCard="outer-cardright card-small"
                cardText="Life Sciences"
                imageStyle="services-images"
                srcImage="assets/Images/LifeSciences.png"
              />
            </div>
            <div className="col-3  ">
              <Cards
                classCard="outer-cardleft card-small"
                cardText="Medical Supplies"
                imageStyle="services-images"
                srcImage="assets/Images/MedicalSupplies.png"
              />
            </div>
            <div className="col-3 ">
              <Cards
                classCard="outer-cardright card-small"
                cardText="Legal Documents"
                imageStyle="services-images"
                srcImage="assets/Images/LegalDocuments.png"
              />
            </div>
            <div className="col-3 ">
              <Cards
                classCard="outer-cardleft card-small"
                cardText="Rush Delivery"
                imageStyle="services-images"
                srcImage="assets/Images/Fast-Delivery.png"
              />
            </div>
          </div>
          <Button btnStyle="button2 outlineOrange  " btnText="Inquire Now" />
        </div>
      </div>
    </div>
  );
}
