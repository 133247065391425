import Landing from "./Components/Landing/landing";
import Button from "./Components/ReusedComponents/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import WhyUs from "./Components/WhyUs/whyus";
import Service from "./Components/Services/services";
import ContactUs from "./Components/ContactUs/contactus";
import AnchorLink from "react-anchor-link-smooth-scroll";

function App() {
  return (
    <div>
      <div className="nav">
        <img src="assets/Images/logo.svg"></img>
        <AnchorLink href="#ContactUs-section">
          <Button btnStyle="button dark" btnText="Contact Us" />
        </AnchorLink>
      </div>
      <Landing />
      <WhyUs />
      <Service />
      <ContactUs />

      <div className="footer">
        <img src="assets/Images/WomanOwned_Icon.png"></img>
        <img src="assets/Images/HIPAACompliant_Icon.png"></img>
      </div>
    </div>
  );
}

export default App;
