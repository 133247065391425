import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./cards.css";

export default function Cards(props) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <div className={props.classCard}>
        <h4>{props.cardTitle}</h4>
        <img src={props.srcImage} className={props.imageStyle} alt="" />
        <CopyToClipboard text={props.cardTextCopy} onCopy={handleCopy}>
          {copied ? <h5>Text Copied! </h5> : <h5>{props.cardTextCopy}</h5>}
        </CopyToClipboard>
        <h5>{props.cardText}</h5>
      </div>
    </>
  );
}
