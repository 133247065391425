import Cards from "../ReusedComponents/cards";
import "bootstrap/dist/css/bootstrap.min.css";
import Clipboard from "react-copy-to-clipboard";
import "./contactus.css";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init();
export default function ContactUs() {
  return (
    <div className="outercontainer-conact" id="ContactUs-section">
      <h1 className="contactus-title" data-aos="fade-down" data-aos-delay="200">
        Reach Us at
      </h1>
      <div
        className="contactus-section row"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div className="col-4">
          <Cards
            cardTitle="Based In"
            cardTextCopy="Headquarters Charlotte NC"
            imageStyle="cardImage"
            srcImage="assets/Images/address 1.svg"
            classCard="contacts-card"
          />
        </div>
        <div className="col-4">
          <Cards
            cardTitle="Email Us"
            cardTextCopy="rachel@rlglogistics.org"
            imageStyle="cardImage"
            srcImage="assets/Images/_x39_.svg"
            classCard="contacts-card"
          />
        </div>
        <div className="col-4">
          <Cards
            cardTitle="Call Us"
            cardTextCopy="980-425-5510"
            imageStyle="cardImage"
            srcImage="assets/Images/14.svg"
            classCard="contacts-card"
          />
        </div>
      </div>
    </div>
  );
}
